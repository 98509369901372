<script setup lang="ts">
import lozad from 'lozad'
import { AsfImageProps } from '@ui/types'
import noImage from 'assets/images/noimage.png'

type LozadObserver = {
  observe: () => void
  observer: IntersectionObserver
}

const props = withDefaults(defineProps<AsfImageProps>(), {
  hiddenMobile: false,
  lazy: false,
  adaptive: false,
  showTitle: false,
  fetchPriority: 'low'
})

const rootNode = ref<Element | undefined>()
const isLoading = ref(props.lazy)
const withSource = computed(() => Array.isArray(props.srcset))

const imageSources = computed(() => {
  if (isLoading.value) {
    return {
      srcset: [],
      src: ''
    }
  }
  return {
    srcset: props.srcset,
    src: props.src || noImage
  }
})

let lozadObserver: LozadObserver
onMounted(() => {
  if (!props.lazy) return

  nextTick(() => {
    lozadObserver = lozad(rootNode.value, {
      load: () => (isLoading.value = false),
      ...props.observerOptions
    })

    lozadObserver.observe()
  })
})

onBeforeUnmount(() => {
  if (lozadObserver && lozadObserver.observer) lozadObserver.observer.disconnect()
})
</script>
<template>
  <picture
    ref="rootNode"
    class="asf-image"
    :class="{
      'is-adaptive': adaptive,
      'is-loading': isLoading,
      'is-hidden-mobile': hiddenMobile
    }"
  >
    <template v-if="withSource">
      <source
        v-for="srcItem in imageSources.srcset"
        :key="srcItem.media"
        :srcset="srcItem.srcset"
        :media="srcItem.media"
        :type="srcItem.type"
      />
    </template>
    <img
      ref="imageRef"
      class="asf-image__node"
      :src="imageSources.src"
      :sizes="sizes ? sizes : undefined"
      :alt="alt ? alt : undefined"
      :width="width ? width : undefined"
      :height="height ? height : undefined"
      :loading="lazy ? 'lazy' : 'eager'"
      :fetchpriority="fetchPriority ? fetchPriority : undefined"
    />
  </picture>
</template>
<style lang="postcss">
@import '@components/atoms/Image/Image.css';
</style>
