import revive_payload_client_YTGlmQWWT5 from "/var/www/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_VeD7Ba96AP from "/var/www/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import preferences_plugin_client_4LgTeayjfu from "/var/www/packages/ui/plugins/preferences.plugin.client.ts";
import components_plugin_KR1HBZs4kY from "/var/www/packages/brandApp/.nuxt/components.plugin.mjs";
import unhead_neSs9z3UJp from "/var/www/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_8FoqvPFTot from "/var/www/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_N1rIkEnC9Q from "/var/www/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_dOTnCc6TUQ from "/var/www/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_rSt9FhEa7a from "/var/www/packages/ui/modules/sanitizeHtml/runtime/plugin.ts";
import plugin_ODWgxYfXYL from "/var/www/packages/ui/modules/lazyHydration/runtime/plugin.ts";
import chunk_reload_client_SeG0EjL5Ec from "/var/www/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import analytics_plugin_SiyD4pwtFI from "/var/www/packages/base/plugins/analytics.plugin.ts";
import e2e_plugin_bhwOU9Bsj1 from "/var/www/packages/base/plugins/e2e.plugin.ts";
import custom_directives_plugin_ZEIbiaHoff from "/var/www/packages/ui/plugins/custom-directives.plugin.ts";
import vee_validate_plugin_1iyRGnnikp from "/var/www/packages/ui/plugins/vee-validate.plugin.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  router_VeD7Ba96AP,
  preferences_plugin_client_4LgTeayjfu,
  components_plugin_KR1HBZs4kY,
  unhead_neSs9z3UJp,
  prefetch_client_8FoqvPFTot,
  composition_N1rIkEnC9Q,
  i18n_dOTnCc6TUQ,
  plugin_rSt9FhEa7a,
  plugin_ODWgxYfXYL,
  chunk_reload_client_SeG0EjL5Ec,
  analytics_plugin_SiyD4pwtFI,
  e2e_plugin_bhwOU9Bsj1,
  custom_directives_plugin_ZEIbiaHoff,
  vee_validate_plugin_1iyRGnnikp
]